// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "N_g9";
export var customEnterpriseDevCallToActionSection = "N_g5";
export var customEnterpriseDevCaseSection = "N_hg";
export var customEnterpriseDevCostSection = "N_hb";
export var customEnterpriseDevExperienceSection = "N_hf";
export var customEnterpriseDevExpertiseSection = "N_hd";
export var customEnterpriseDevIndustriesSection = "N_g4";
export var customEnterpriseDevPracticesSection = "N_hc";
export var customEnterpriseDevPrimeSection = "N_g2";
export var customEnterpriseDevProcessSection = "N_g7";
export var customEnterpriseDevQuotesSection = "N_hh";
export var customEnterpriseDevServicesSection = "N_g3";
export var customEnterpriseDevSolutionSection = "N_g6";
export var customEnterpriseDevTechSection = "N_g8";